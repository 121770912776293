<template>
  <div>
    <vimeo-player
      v-if="checkType == 1"
      ref="playerVimeo"
      :video-id="videoID"
      :options="options"
      :autoplay="false"
      :data-id="idElemVimeo"
      :dataVideo="dataVideo"
      @ready="onReady"
      @ended="ended"
      @playing="playing"
      @pause="paused"
    />
    <vimeo-player
      v-if="checkType == 2"
      ref="playerVimeo"
      :video-url="src"
      :options="options"
      :autoplay="false"
      :data-id="idElemVimeo"
      :dataVideo="dataVideo"
      @ready="onReady"
      @ended="ended"
      @playing="playing"
      @pause="paused"
    />
    <h3 class="mt-2" v-if="dataVideo.caption != null">
      {{ dataVideo.caption }}
    </h3>
    <div class="my-2" v-if="textSettingShow != ''">
      {{ textSettingShow }}
    </div>
  </div>
</template>
<script>
import { eventBus } from "../../main";
import { Constants } from "../../utils/constants";
export default {
  props: ["src", "idElemVimeo", "idLastVimeo", "timeWatching", "dataVideo"],
  data() {
    return {
      videoID: "",
      hash: "",
      videoURL: "",
      videoTitle: "",
      checkType: 1,
      height: "",
      width: "",
      options: {
        // loop: true,
        // muted: true,
        autoplay: false,
        responsive: true,
        // autopause: false,
      },
      playerReady: false,
      isLastIdData: null,
      dataSettingElm: null,
      textSettingShow: "",
      endVideo: false,
      timeCollectStart: 0,
      processId: null,
      progress: 0,
      timeCollectEnd: 0,
      checkTime: 0,
      statusClick: {
        start: 0,
        paused: 1,
        end: 2,
      },
    };
  },
  created() {
    this.getId();
    this.height = this.heightPlayer;
    this.width = this.widthPlayer;
    localStorage.removeItem("currentTimeVimeo");
    if (this.dataVideo.setting.idBlock === this.idElemVimeo) {
      this.dataSettingElm = this.dataVideo.setting;
    }
  },
  mounted() {
    eventBus.$on("getCurrtentTimeVimeo", (data) => {
      this.isLastIdData = data;
      this.getCurrentTimeVimeo();
    });
  },
  methods: {
    getId() {
      const regExp =
        /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)?(channels\/)*([0-9))([a-z]*\/)*([0-9]{6,11})((\?h=|\?&h=)[a-zA-A0-9]*)?[?]?(\/([a-zA-Z0-9]*))?.*/;
      var match = this.src.match(regExp);
      const result = regExp.exec(this.src).slice(1);
      if (match) {
        this.videoID = match[6];
        if (this.src.includes("?")) {
          this.checkType = 2;
          this.hash = this.src.split("?")[1].replace("h=", "");
          this.videoURL =
            "https://vimeo.com/video/" + this.videoID + "/" + this.hash;
        }
        if (
          !this.src.includes("?") &&
          result[4] &&
          !result[4].includes("video") &&
          result[4].includes("/")
        ) {
          this.videoID = result[4].slice(0, -1);
          this.hash = result[5];
          this.videoURL =
            "https://vimeo.com/video/" + this.videoID + "/" + this.hash;
        }
        if (
          this.src &&
          !this.src.includes("?") &&
          result[7] !== undefined &&
          result[7].includes("video") &&
          result[7].includes("?h")
        ) {
          this.videoID = result[6];
          this.hash = result[7];
          this.videoURL = "https://vimeo.com/video/" + this.videoID + this.hash;
        }
        if (
          this.src &&
          !this.src.includes("?") &&
          result[2] !== undefined &&
          result[2].includes("player") &&
          result[2].includes("?h")
        ) {
          this.videoID = result[6];
          this.hash = result[7];
          this.videoURL = "https://vimeo.com/video/" + this.videoID + this.hash;
        }
        if (
          this.src &&
          !this.src.includes("?") &&
          result[5] !== undefined &&
          result[5].includes("video") &&
          result[9].includes("/")
        ) {
          this.videoID = result[6];
          this.hash = result[7];
          this.videoURL =
            "https://vimeo.com/video/" + this.videoID + "/" + this.hash;
        }
      } else {
        console.log("not a vimeo url");
      }
    },
    onReady() {
      this.playerReady = true;
      this.setCurrentTimeVideo();
      // var iframe = this.$refs.playerVimeo.$el;
      // console.log(iframe);
      // var elmnt =
      //   iframe.firstChild.contentWindow.document.getElementsByClassName(
      //     "vp-outro-image"
      //   )[0];
      // elmnt.style.display = "none";
      // iframe.firstChild.addEventListener("load", (ev) => {
      //   const new_style_element = document.createElement("style");
      //   new_style_element.textContent = ".vp-outro-imag { display: none; }";
      //   ev.target.contentDocument.head.appendChild(new_style_element);
      //   console.log(ev.target);
      // });
      this.runActionSetting(this.$refs.playerVimeo, this);
    },
    // play() {
    //   this.$refs.playerVimeo.play();
    //   console.log("123");
    // },
    async playing() {
      this.$refs.playerVimeo.play();
      this.videoTitle = await this.$refs.playerVimeo.player.getVideoTitle();
      this.timeCollectStart = this.checkTime;
      let totalTime = await this.$refs.playerVimeo.player.getDuration();
      this.processId = setInterval(() => {
        this.$refs.playerVimeo.player.getCurrentTime().then((time) => {
          let progress = (time / totalTime) * 100;

          this.progress = progress < 100 ? progress : 100;
          this.updateTime(time);
        });
      }, 100);
      this.logDataAction(0);
    },
    // pause() {
    //   this.$refs.playerVimeo.pause();
    // },
    async paused() {
      this.$refs.playerVimeo.pause();
      clearInterval(this.processId);
      console.log("Vimeo paused");
      this.timeCollectEnd = this.checkTime;
      let stateVideo = await this.$refs.playerVimeo.player.getEnded();
      if (!stateVideo) this.logDataAction(1);
    },
    ended() {
      this.endVideo = true;
      this.timeCollectEnd = this.checkTime;
      this.logDataAction(2);
    },
    setCurrentTimeVideo() {
      if (this.idElemVimeo === this.idLastVimeo) {
        this.$refs.playerVimeo.player.setCurrentTime(this.timeWatching);
      }
    },
    runActionSetting(elem, _this) {
      const {
        minuteStart,
        minuteEnd,
        secondStart,
        secondEnd,
        textConfig,
        textEnd,
      } = _this.dataSettingElm;
      if (
        minuteStart !== "" &&
        minuteStart != null &&
        minuteEnd != "" &&
        minuteEnd != null
      ) {
        const timeStart =
          _this.convertToSeconds(parseInt(minuteStart)) + parseInt(secondStart);
        const timeEnd =
          _this.convertToSeconds(parseInt(minuteEnd)) + parseInt(secondEnd);
        if (timeStart === 0 && timeEnd === 0) return;
        setInterval(function () {
          if (!elem.player) return;
          elem.player.getCurrentTime().then(function (response) {
            let minutedPlay = Math.round(response);
            if (timeStart <= minutedPlay && minutedPlay <= timeEnd) {
              _this.textSettingShow = textConfig;
              if (_this.endVideo === true) {
                _this.textSettingShow = textEnd;
              }
            } else {
              if (_this.endVideo === true) {
                _this.textSettingShow = textEnd;
              } else {
                _this.textSettingShow = "";
              }
            }
          });
        }, 50);
      }
    },
    convertToSeconds(minutes) {
      return minutes * 60;
    },
    getCurrentTimeVimeo() {
      var self = this;
      if (self.$refs.playerVimeo !== undefined) {
        this.$refs.playerVimeo.player
          .getCurrentTime()
          .then(function (seconds) {
            const getIdElm = self.$refs.playerVimeo.$el.dataset.id;
            if (localStorage.getItem("currentTimeVimeo") !== null) {
              const dataVimeoTime = JSON.parse(
                localStorage.getItem("currentTimeVimeo")
              );
              dataVimeoTime
                .filter((item) => item.lasteId == true)
                .forEach((someobject) => (someobject.lasteId = false));
              const entry = {
                id: getIdElm,
                time: seconds,
                lasteId: this.idElemVimeo === this.isLastIdData ? false : true,
              };
              const dataFilter = dataVimeoTime.filter(
                (item) => item.id === getIdElm
              );

              if (dataFilter.length > 0) {
                dataVimeoTime
                  .filter((someobject) => someobject.id == getIdElm)
                  .forEach((someobject) => {
                    someobject.time = seconds;
                    someobject.lasteId =
                      this.idElemVimeo === this.isLastIdData ? false : true;
                  });
              } else {
                dataVimeoTime.push(entry);
              }
              localStorage.setItem(
                "currentTimeVimeo",
                JSON.stringify(dataVimeoTime)
              );
              // const dataCurrentTime = { getIdElm, seconds };
            } else {
              const entry = {
                id: getIdElm,
                time: seconds,
                lasteId: this.idElemVimeo === this.isLastIdData ? false : true,
              };
              const object = new Array();
              console.log(object);
              object.push(entry);
              localStorage.setItem("currentTimeVimeo", JSON.stringify(object));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    updateTime(time) {
      time = Math.round(time);
      let hours = Math.floor(time / 3600);
      let minutes = Math.floor((time - hours * 3600) / 60);
      let seconds = time - hours * 3600 - minutes * 60;

      hours = hours < 10 ? "0" + hours : hours;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      this.checkTime = hours + ":" + minutes + ":" + seconds;
    },
    logDataAction(status) {
      const req = {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        title: this.$route.meta.title,
        referrer_url:
          this.refer_url && this.refer_url != "/"
            ? window.location.origin + this.refer_url
            : window.location.href,
        url: window.document.activeElement.href
          ? window.document.activeElement.href
          : window.document.activeElement.baseURI,
        id_button: window.document.activeElement.name,
        video_url: this.src,
        video_title: this.videoTitle,
      };
      if (status == this.statusClick["start"]) {
        req.video_time_start_view = this.timeCollectStart;
      } else if (status == this.statusClick["paused"]) {
        req.video_time_end_view = this.timeCollectEnd;
        req.video_time_view =
          this.getSecond(this.timeCollectEnd) -
          this.getSecond(this.timeCollectStart);
      } else if (status == this.statusClick["end"]) {
        req.video_time_end_view = this.timeCollectEnd;
        req.video_time_view =
          this.getSecond(this.checkTime) -
          this.getSecond(this.timeCollectStart);
        req.video_end_view = true;
      }
      if (this.$route.name.includes("DetailPageLibrary")) {
        req.id_page = this.$route.params.idPage;
      } else if (this.$route.name.includes("staticPage")) {
        req.id_fix_page = this.$route.params.id;
      }
      this.$store.dispatch("logActive", req);
    },
    getSecond(time) {
      if (time == 0) return 0;
      else {
        const [hours, minutes, seconds] = time.toString().split(":");
        return +hours * 60 * 60 + +minutes * 60 + +seconds;
      }
    },
  },
};
</script>
<style>
.vp-outro-image:hover {
  transform: scale(1);
}
</style>
