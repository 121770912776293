<template>
  <div>
    <youtube
      :id="idElemYoutube"
      :video-id="videoId"
      :player-vars="playerVars"
      :dataVideo="dataVideo"
      :resize="true"
      :fitParent="true"
      ref="youtube"
      @playing="playing"
      @paused="paused"
      @ready="ready"
      @ended="ended"
      class="youtubePlayer"
    ></youtube>
    <h3 class="mt-2" v-if="dataVideo.caption != null">
      {{ dataVideo.caption }}
    </h3>
    <div class="my-2" v-if="textSettingShow != ''">
      {{ textSettingShow }}
    </div>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import { getIdFromUrl } from "vue-youtube";
import { Constants } from "../../utils/constants";
export default {
  data() {
    return {
      videoId: null,
      elemtId: null,
      playerVars: {
        autoplay: 0,
        loop: 0,
        controls: 1,
        showinfo: 0,
        autohide: 1,
        mute: 0,
        modestbranding: 1,
      },
      isLastIdData: null,
      dataSettingElm: null,
      textSettingShow: "",
      endVideo: false,
      videoTitle: "",
      timeCollectStart: 0,
      processId: null,
      progress: 0,
      timeCollectEnd: 0,
      checkTime: 0,
      statusClick: {
        start: 0,
        paused: 1,
        end: 2,
      },
    };
  },
  props: [
    "src",
    "heightPlayer",
    "widthPlayer",
    "idElemYoutube",
    "idLastYoutube",
    "timeWatching",
    "dataVideo",
  ],
  created() {
    this.getId();
    this.elemtId = this.idElemYoutube;
    localStorage.removeItem("currentTimeYoutu");
    if (this.dataVideo.setting.idBlock === this.idElemYoutube) {
      this.dataSettingElm = this.dataVideo.setting;
    }
  },
  mounted() {
    eventBus.$on("getCurrtentTimeYoutu", (data) => {
      this.isLastIdData = data;
      this.getCurrentTime();
    });
  },
  computed: {
    // player() {
    //   return this.$refs.youtube.player.playVideo();
    // },
    ply() {
      return this.$refs.youtube.player;
    },
  },
  methods: {
    getId() {
      const youtubeId = getIdFromUrl(this.src);
      this.videoId = youtubeId;
    },
    ready() {
      this.setCurrentTimeYoutube();
      this.runActionSetting(this.$refs.youtube);
    },
    // playing() {
    //   console.log("Youtube playing");
    // },
    async playing() {
      console.log("Youtube playing");
      this.timeCollectStart = this.checkTime;
      let video = await this.ply.getIframe();
      this.videoTitle = video.title;
      let totalTime = await this.ply.getDuration();

      this.processId = setInterval(() => {
        this.ply.getCurrentTime().then((time) => {
          let progress = (time / totalTime) * 100;

          this.progress = progress < 100 ? progress : 100;
          this.updateTime(time);
        });
      }, 100);
      this.getCurrentTime();
      this.logDataAction(0);
    },
    async paused() {
      clearInterval(this.processId);
      console.log("Youtube paused");
      this.timeCollectEnd = this.checkTime;
      this.logDataAction(1);
    },
    ended() {
      console.log("Youtube ending");
      this.endVideo = true;
      this.timeCollectEnd = this.checkTime;
      this.logDataAction(2);
    },
    setCurrentTimeYoutube() {
      if (this.idElemYoutube === this.idLastYoutube) {
        this.$refs.youtube.player.seekTo(this.timeWatching);
      }
    },
    runActionSetting(elem) {
      const {
        idBlock,
        minuteStart,
        minuteEnd,
        secondStart,
        secondEnd,
        textConfig,
        textEnd,
      } = this.dataSettingElm;
      let _this = this;
      if (minuteStart != null && minuteEnd != null) {
        const timeStart =
          this.convertToSeconds(parseInt(minuteStart)) + parseInt(secondStart);
        const timeEnd =
          this.convertToSeconds(parseInt(minuteEnd)) + parseInt(secondEnd);
        if (timeStart === 0 && timeEnd === 0) return;
        if (elem.player !== undefined && elem.$el.id === idBlock) {
          setInterval(function () {
            if (elem.player === undefined) return;
            elem.player.getCurrentTime().then(function (response) {
              let minutedPlay = Math.round(response);
              if (timeStart <= minutedPlay && minutedPlay <= timeEnd) {
                _this.textSettingShow = textConfig;
                if (_this.endVideo === true) {
                  _this.textSettingShow = textEnd;
                }
              } else {
                if (_this.endVideo === true) {
                  _this.textSettingShow = textEnd;
                } else {
                  _this.textSettingShow = "";
                }
              }
            });
          }, 50);
        }
      }
    },
    convertToSeconds(minutes) {
      return minutes * 60;
    },
    getCurrentTime() {
      const getIdElem = this.elemtId;
      let isLast = false;
      if (getIdElem === this.isLastIdData) isLast = true;
      this.$refs.youtube.player.getCurrentTime().then(function (response) {
        if (localStorage.getItem("currentTimeYoutu") !== null) {
          const dataYoutubeTime = JSON.parse(
            localStorage.getItem("currentTimeYoutu")
          );
          dataYoutubeTime
            .filter((item) => item.lasteId == true)
            .forEach((someobject) => (someobject.lasteId = false));
          const entry = {
            id: getIdElem,
            time: Math.round(response),
            lasteId: isLast,
          };
          const dataFilter = dataYoutubeTime.filter(
            (item) => item.id === getIdElem
          );

          if (dataFilter.length > 0) {
            dataYoutubeTime
              .filter((someobject) => someobject.id == getIdElem)
              .forEach((someobject) => {
                someobject.time = Math.round(response);
                someobject.lasteId = isLast;
              });
          } else {
            dataYoutubeTime.push(entry);
          }
          localStorage.setItem(
            "currentTimeYoutu",
            JSON.stringify(dataYoutubeTime)
          );
        } else {
          const entry = {
            id: getIdElem,
            time: Math.round(response),
            lasteId: isLast,
          };
          const object = new Array();
          object.push(entry);
          localStorage.setItem("currentTimeYoutu", JSON.stringify(object));
        }
      });
      // }
    },
    updateTime(time) {
      time = Math.round(time);
      let hours = Math.floor(time / 3600);
      let minutes = Math.floor((time - hours * 3600) / 60);
      let seconds = time - hours * 3600 - minutes * 60;

      hours = hours < 10 ? "0" + hours : hours;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      this.checkTime = hours + ":" + minutes + ":" + seconds;
    },
    logDataAction(status) {
      const req = {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        title: this.$route.meta.title,
        referrer_url:
          this.refer_url && this.refer_url != "/"
            ? window.location.origin + this.refer_url
            : window.location.href,
        url: window.document.activeElement.href
          ? window.document.activeElement.href
          : window.document.activeElement.baseURI,
        id_button: window.document.activeElement.name,
        video_url: this.src,
        video_title: this.videoTitle,
      };
      if (status == this.statusClick["start"]) {
        req.video_time_start_view = this.timeCollectStart;
      } else if (status == this.statusClick["paused"]) {
        req.video_time_end_view = this.timeCollectEnd;
        req.video_time_view =
          this.getSecond(this.timeCollectEnd) -
          this.getSecond(this.timeCollectStart);
      } else if (status == this.statusClick["end"]) {
        req.video_time_end_view = this.timeCollectEnd;
        req.video_time_view =
          this.getSecond(this.checkTime) -
          this.getSecond(this.timeCollectStart);
        req.video_end_view = true;
      }
      if (this.$route.name.includes("DetailPageLibrary")) {
        req.id_page = this.$route.params.idPage;
      } else if (this.$route.name.includes("staticPage")) {
        req.id_fix_page = this.$route.params.id;
      }
      this.$store.dispatch("logActive", req);
    },
    getSecond(time) {
      if (time == 0) return 0;
      else {
        const [hours, minutes, seconds] = time.toString().split(":");
        return +hours * 60 * 60 + +minutes * 60 + +seconds;
      }
    },
  },
};
</script>

<style></style>
