<template>
  <div>
    <vue-pdf-app
      style="height: 100vh"
      :id="idComponent"
      :pdf="src"
      :config="config"
      @open="openHandler"
      @pages-rendered="pagesRenderedHandler"
    ></vue-pdf-app>
  </div>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import moment from "moment";
import { Constants } from "../../utils/constants";
import { mapGetters } from "vuex";

export default {
  components: {
    VuePdfApp,
  },
  data() {
    return {
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      config: {
        toolbar: {
          toolbarViewerMiddle: false,
          toolbarViewerRight: false,
          toolbarViewerLeft: {
            findbar: false,
          },
        },
        secondaryToolbar: false,
      },
      listPageView: [],
      timeStart: null,
      timeEnd: null,
      pdf_end_view: false,
      dynamicTimeEachPage: {},
      prePageNumber: null,
      statusLog: {
        normal: 0,
        end: 1,
      },
    };
  },
  props: ["src", "idComponent", "dataInfo"],
  async created() {},
  computed: {
    ...mapGetters(["clickOnPDF"]),
  },
  methods: {
    async openHandler(pdfApp) {
      pdfApp.pdfDocument._transport.CMapReaderFactory.baseUrl =
        "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/";
      this.timeStart = moment(String(new Date())).format(
        "YYYY-MM-DD dddd HH:mm:ss"
      );
      this.numPages = pdfApp.pdfDocument && pdfApp.pdfDocument.numPages;
      if (this.numPages > 0) {
        for (let index = 0; index < this.numPages; index++) {
          let namePage = "page_" + (index + 1);
          let nameStart = "time_start_" + (index + 1);
          let nameEnd = "time_end_" + (index + 1);
          this.dynamicTimeEachPage[namePage] = {};
          this.dynamicTimeEachPage[namePage][nameStart] = null;
          this.dynamicTimeEachPage[namePage][nameEnd] = null;
        }
      }
    },
    pagesRenderedHandler(pdfApp) {
      //process for first page when popup show
      this.page = pdfApp.pdfViewer && pdfApp.pdfViewer.currentPageNumber;
      this.prePageNumber = this.page;
      this.dynamicTimeEachPage["page_" + this.page]["time_start_" + this.page] =
        moment(String(new Date())).format("YYYY/MM/DD dddd HH:mm:ss");
      this.listPageView.push(this.page);
      if (this.page == this.numPages) this.pdf_end_view = true;

      const self = this;
      pdfApp.pdfViewer.eventBus.on("pagechanging", ({ pageNumber }) => {
        //process for previous view page
        self.prePageNumber = self.page;
        this.dynamicTimeEachPage["page_" + self.prePageNumber][
          "time_end_" + self.prePageNumber
        ] = moment(String(new Date())).format("YYYY/MM/DD dddd HH:mm:ss");
        self.logDataAction(self.prePageNumber, self.statusLog["normal"]);
        //process for curent page
        self.page = pageNumber;
        if (pageNumber == self.numPages) self.pdf_end_view = true;
        this.dynamicTimeEachPage["page_" + pageNumber][
          "time_start_" + pageNumber
        ] = moment(String(new Date())).format("YYYY/MM/DD dddd HH:mm:ss");
        this.listPageView.push(pageNumber);
      });
    },
    changePageData(pageNumber) {
      let PDFTitle = this.title;
      let PDFUrl = this.src;
      this.listPageView.push(pageNumber);
      console.log(PDFTitle + "_" + PDFUrl + "_" + this.listPageView);
    },
    logDataAction(pageNumber, status) {
      if (!this.$route.fullPath.includes(Constants.ADMIN)) {
        const req = {
          shop_id: localStorage.getItem(Constants.SHOP_ID),
          title: this.$route.meta.title,
          referrer_url:
            this.refer_url && this.refer_url != "/"
              ? window.location.origin + this.refer_url
              : window.location.href,
          url: window.document.activeElement.href
            ? window.document.activeElement.href
            : window.document.activeElement.baseURI,
          pdf_title: this.dataInfo && this.dataInfo.name,
          pdf_url: this.src,
        };
        if (status == this.statusLog["normal"]) {
          req.pdf_time_start_view =
            this.dynamicTimeEachPage["page_" + pageNumber][
              "time_start_" + pageNumber
            ];
          req.pdf_time_end_view =
            this.dynamicTimeEachPage["page_" + pageNumber][
              "time_end_" + pageNumber
            ];
          req.pdf_page_number = pageNumber;
          if (pageNumber == this.numPages) {
            req.pdf_end_view = this.pdf_end_view;
          }
        } else if (status == this.statusLog["end"]) {
          req.all_pages_view = this.listPageView;
          req.pdf_time_start_view = this.timeStart;
          req.pdf_time_end_view = this.timeEnd;
          req.pdf_end_view = this.pdf_end_view;
          req.pdf_view_count = this.dataInfo && this.dataInfo.view;
        }
        let time_view = moment
          .utc(
            moment(req.pdf_time_end_view, "YYYY/MM/DD HH:mm:ss").diff(
              moment(req.pdf_time_start_view, "YYYY/MM/DD HH:mm:ss")
            )
          )
          .format("HH:mm:ss");
        req.pdf_time_view = this.getSecond(time_view);
        if (this.$route.name.includes("DetailPageLibrary")) {
          req.id_page = this.$route.params.idPage;
        } else if (this.$route.name.includes("staticPage")) {
          req.id_fix_page = this.$route.params.id;
        }

        this.$store.dispatch("logActive", req);
      }
    },
    getSecond(time) {
      if (time == 0) return 0;
      else {
        const [hours, minutes, seconds] = time.toString().split(":");
        return +hours * 60 * 60 + +minutes * 60 + +seconds;
      }
    },
  },
  beforeDestroy() {
    this.timeEnd = moment(String(new Date())).format(
      "YYYY/MM/DD dddd HH:mm:ss"
    );
    this.listPageView = this.listPageView.filter(function (
      value,
      index,
      array
    ) {
      return array.indexOf(value) === index;
    });
    this.logDataAction(0, this.statusLog["end"]);
    this.$store.commit("set", ["clickOnPDF", false]);
  },
};
</script>
